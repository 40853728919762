var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _vm.isOld
              ? _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm revisionLayout",
                    attrs: {
                      title: "개정",
                      noHeader: true,
                      topClass: "topcolor-orange",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-2" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    required: _vm.editable && _vm.isRevision,
                                    editable: _vm.editable && _vm.isRevision,
                                    label: "개정번호",
                                    name: "revisionNum",
                                  },
                                  model: {
                                    value: _vm.wsData.revisionNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.wsData, "revisionNum", $$v)
                                    },
                                    expression: "wsData.revisionNum",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-2" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    editable: false,
                                    label: "개정일시",
                                    name: "regDtStr",
                                  },
                                  model: {
                                    value: _vm.wsData.regDtStr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.wsData, "regDtStr", $$v)
                                    },
                                    expression: "wsData.regDtStr",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-2" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    editable: false,
                                    label: "개정자",
                                    name: "regUserName",
                                  },
                                  model: {
                                    value: _vm.wsData.regUserName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.wsData, "regUserName", $$v)
                                    },
                                    expression: "wsData.regUserName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    required: _vm.editable && _vm.isRevision,
                                    editable: _vm.editable && _vm.isRevision,
                                    label: "개정사유",
                                    name: "revisionContent",
                                  },
                                  model: {
                                    value: _vm.wsData.revisionContent,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.wsData,
                                        "revisionContent",
                                        $$v
                                      )
                                    },
                                    expression: "wsData.revisionContent",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "작업표준 상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-select" },
                      [
                        _vm.popupParam.mdmSwsId
                          ? _c("c-select", {
                              attrs: {
                                editable: !_vm.isRevision,
                                comboItems: _vm.revdata,
                                type: "custom",
                                typetext: "개정이력",
                                itemText: "revisionNum",
                                itemValue: "mdmSwsId",
                                name: "selectedMdmSwsId",
                                label: "",
                              },
                              on: { input: _vm.rowClickRev },
                              model: {
                                value: _vm.selectedMdmSwsId,
                                callback: function ($$v) {
                                  _vm.selectedMdmSwsId = $$v
                                },
                                expression: "selectedMdmSwsId",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            !_vm.isOld && _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "이전 정비작업 불러오기",
                                    icon: "upload_file",
                                  },
                                  on: { btnClicked: _vm.copyData },
                                })
                              : _vm._e(),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.isOld &&
                                    !_vm.isRevision,
                                  expression:
                                    "editable && isOld && !isRevision",
                                },
                              ],
                              attrs: { label: "개정", icon: "restart_alt" },
                              on: { btnClicked: _vm.setRevision },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable && _vm.isOld && _vm.isRevision,
                                  expression: "editable && isOld && isRevision",
                                },
                              ],
                              attrs: { label: "개정취소", icon: "restart_alt" },
                              on: { btnClicked: _vm.cancelRevision },
                            }),
                            _vm.editable && _vm.isOld
                              ? _c("c-btn", {
                                  attrs: { label: "삭제", icon: "remove" },
                                  on: { btnClicked: _vm.deleteSwsInfo },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.wsData,
                                    mappingType: _vm.mappingType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveSwsInfo,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "정비작업표준 명",
                              name: "swsName",
                            },
                            model: {
                              value: _vm.wsData.swsName,
                              callback: function ($$v) {
                                _vm.$set(_vm.wsData, "swsName", $$v)
                              },
                              expression: "wsData.swsName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              codeGroupCd: "WO_WORK_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "woWorkTypeCd",
                              label: "정비유형",
                            },
                            model: {
                              value: _vm.wsData.woWorkTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.wsData, "woWorkTypeCd", $$v)
                              },
                              expression: "wsData.woWorkTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.wsData.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.wsData, "plantCd", $$v)
                              },
                              expression: "wsData.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-equip", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "대표설비Tag",
                              name: "equipmentCd",
                            },
                            on: { dataChange: _vm.equipChange },
                            model: {
                              value: _vm.wsData.equipmentCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.wsData, "equipmentCd", $$v)
                              },
                              expression: "wsData.equipmentCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-func-location", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.wsData.plantCd,
                              editable: _vm.editable,
                              label: "기능위치",
                              name: "funcLocationCd",
                            },
                            model: {
                              value: _vm.wsData.funcLocationCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.wsData, "funcLocationCd", $$v)
                              },
                              expression: "wsData.funcLocationCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable,
                              codeGroupCd: "WORK_IMPORTANT_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "workImportantCd",
                              label: "작업중요도",
                            },
                            model: {
                              value: _vm.wsData.workImportantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.wsData, "workImportantCd", $$v)
                              },
                              expression: "wsData.workImportantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-dept", {
                            attrs: {
                              type: "edit",
                              required: true,
                              editable: _vm.editable,
                              label: "정비오더 발행부서",
                              name: "workDeptCd",
                            },
                            model: {
                              value: _vm.wsData.workDeptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.wsData, "workDeptCd", $$v)
                              },
                              expression: "wsData.workDeptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              data: _vm.wsData,
                              type: "user",
                              label: "작업계획자",
                              name: "planUserId",
                            },
                            model: {
                              value: _vm.wsData.planUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.wsData, "planUserId", $$v)
                              },
                              expression: "wsData.planUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-dept-multi", {
                            attrs: {
                              required: "",
                              isArray: false,
                              editable: _vm.editable,
                              label: "정비작업팀",
                              name: "workDepts",
                            },
                            model: {
                              value: _vm.wsData.workDepts,
                              callback: function ($$v) {
                                _vm.$set(_vm.wsData, "workDepts", $$v)
                              },
                              expression: "wsData.workDepts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "사용여부",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.wsData.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.wsData, "useFlag", $$v)
                              },
                              expression: "wsData.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              rows: 7,
                              label: "정비요청 세부사항",
                              name: "remark",
                            },
                            model: {
                              value: _vm.wsData.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.wsData, "remark", $$v)
                              },
                              expression: "wsData.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c(
                            "c-table",
                            {
                              ref: "gridCost",
                              attrs: {
                                title: "비용정산 규칙",
                                columns: _vm.gridCost.columns,
                                data: _vm.wsData.costList,
                                gridHeight: _vm.gridCost.height,
                                editable: _vm.editable,
                                hideBottom: true,
                                isExcelDown: false,
                                filtering: false,
                                isFullScreen: false,
                                columnSetting: false,
                                selection: "multiple",
                                rowKey: "costCenterCd",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "table-button" },
                                [
                                  _c(
                                    "q-btn-group",
                                    { attrs: { outline: "" } },
                                    [
                                      _vm.editable
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "추가",
                                              icon: "add",
                                            },
                                            on: { btnClicked: _vm.addRowCost },
                                          })
                                        : _vm._e(),
                                      _vm.editable
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "삭제",
                                              icon: "remove",
                                            },
                                            on: {
                                              btnClicked: _vm.removeRowCost,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c(
                            "c-table",
                            {
                              ref: "grid0",
                              attrs: {
                                title: "관련설비",
                                columns: _vm.grid0.columns,
                                data: _vm.grid0.data,
                                gridHeight: _vm.grid0.height,
                                editable: _vm.editable,
                                hideBottom: true,
                                isExcelDown: false,
                                filtering: false,
                                isFullScreen: false,
                                columnSetting: false,
                                selection: "multiple",
                                rowKey: "equipmentCd",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "table-button" },
                                [
                                  _c(
                                    "q-btn-group",
                                    { attrs: { outline: "" } },
                                    [
                                      _vm.editable && _vm.isOld
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "추가",
                                              icon: "add",
                                            },
                                            on: { btnClicked: _vm.addRowEquip },
                                          })
                                        : _vm._e(),
                                      _vm.editable && _vm.isOld
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "삭제",
                                              icon: "remove",
                                            },
                                            on: {
                                              btnClicked: _vm.removeRowEquip,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              { attrs: { noHeader: true, noMarginPadding: true } },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-table", {
                        ref: "gridWork",
                        attrs: {
                          title: "작업계획 목록",
                          columns: _vm.gridWork.columns,
                          data: _vm.gridWork.data,
                          gridHeight: _vm.gridWork.height,
                          editable: _vm.editable,
                          isDense: true,
                          bgHeaderClass: "bg-grid-header-main",
                          hideBottom: true,
                          isExcelDown: false,
                          gridHeightAuto: true,
                          filtering: false,
                          isFullScreen: false,
                          columnSetting: false,
                        },
                        on: { rowClick: _vm.rowClick },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c(
                        "q-form",
                        { ref: "editForm2" },
                        [
                          _c(
                            "c-card",
                            {
                              staticClass: "cardClassDetailForm",
                              attrs: { title: "작업계획 상세" },
                            },
                            [
                              _c(
                                "template",
                                { slot: "card-button" },
                                [
                                  _c(
                                    "q-btn-group",
                                    { attrs: { outline: "" } },
                                    [
                                      _vm.editable &&
                                      _vm.isOld &&
                                      !_vm.isRevision
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "작업계획 추가",
                                              icon: "add",
                                            },
                                            on: { btnClicked: _vm.addRowWork },
                                          })
                                        : _vm._e(),
                                      _vm.editable &&
                                      _vm.isOld &&
                                      _vm.selectedWork &&
                                      _vm.workUpdateMode
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "작업계획 삭제",
                                              icon: "remove",
                                            },
                                            on: {
                                              btnClicked: _vm.removeRowWork,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.editable &&
                                      _vm.isOld &&
                                      _vm.selectedWork
                                        ? _c("c-btn", {
                                            attrs: {
                                              url: _vm.saveWorkUrl,
                                              isSubmit: _vm.isSave2,
                                              param: _vm.workData,
                                              mappingType: _vm.mappingType2,
                                              label: "작업계획 저장",
                                              icon: "save",
                                            },
                                            on: {
                                              beforeAction: _vm.saveWork,
                                              btnCallback: _vm.saveCallbackWork,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("template", { slot: "card-detail" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                                    staticStyle: {
                                      "padding-top": "25px !important",
                                    },
                                  },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required: true,
                                        editable:
                                          _vm.editable && _vm.selectedWork,
                                        label: "항목번호",
                                        name: "sortOrder",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.workData.sortOrder,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.workData,
                                            "sortOrder",
                                            $$v
                                          )
                                        },
                                        expression: "workData.sortOrder",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5",
                                    staticStyle: {
                                      "padding-top": "25px !important",
                                    },
                                  },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required: true,
                                        editable:
                                          _vm.editable && _vm.selectedWork,
                                        label: "작업명",
                                        name: "mdmSwsWorkName",
                                      },
                                      model: {
                                        value: _vm.workData.mdmSwsWorkName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.workData,
                                            "mdmSwsWorkName",
                                            $$v
                                          )
                                        },
                                        expression: "workData.mdmSwsWorkName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                                    staticStyle: {
                                      "padding-top": "25px !important",
                                    },
                                  },
                                  [
                                    _c("c-select", {
                                      attrs: {
                                        required: true,
                                        editable:
                                          _vm.editable && _vm.selectedWork,
                                        codeGroupCd: "WO_OPR_TYPE_CD",
                                        type: "edit",
                                        itemText: "codeName",
                                        itemValue: "code",
                                        name: "workOprTypeCd",
                                        label: "작업제어구분",
                                      },
                                      on: { datachange: _vm.changeWorkOprType },
                                      model: {
                                        value: _vm.workData.workOprTypeCd,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.workData,
                                            "workOprTypeCd",
                                            $$v
                                          )
                                        },
                                        expression: "workData.workOprTypeCd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.workData.workOprTypeCd !== "WOTC000001"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                                        staticStyle: {
                                          "padding-top": "25px !important",
                                        },
                                      },
                                      [
                                        _c("c-vendor", {
                                          attrs: {
                                            editable:
                                              _vm.editable && _vm.selectedWork,
                                            label: "작업업체",
                                            name: "vendorCd",
                                          },
                                          model: {
                                            value: _vm.workData.vendorCd,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.workData,
                                                "vendorCd",
                                                $$v
                                              )
                                            },
                                            expression: "workData.vendorCd",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.workData.workOprTypeCd === "WOTC000001"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                                        staticStyle: {
                                          "padding-top": "25px !important",
                                        },
                                      },
                                      [
                                        _c("c-dept", {
                                          attrs: {
                                            type: "edit",
                                            isFirstValue: _vm.workUpdateMode,
                                            editable:
                                              _vm.editable && _vm.selectedWork,
                                            label: "작업부서",
                                            name: "workDeptCd",
                                          },
                                          model: {
                                            value: _vm.workData.workDeptCd,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.workData,
                                                "workDeptCd",
                                                $$v
                                              )
                                            },
                                            expression: "workData.workDeptCd",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                                  },
                                  [
                                    _c("c-checkbox", {
                                      attrs: {
                                        isArray: false,
                                        editable:
                                          _vm.editable && _vm.selectedWork,
                                        codeGroupCd: "SOP_WORK_TYPE_CD",
                                        itemText: "codeName",
                                        itemValue: "code",
                                        label: "작업허가서 구분",
                                        name: "workpermitTypes",
                                      },
                                      model: {
                                        value: _vm.workData.workpermitTypes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.workData,
                                            "workpermitTypes",
                                            $$v
                                          )
                                        },
                                        expression: "workData.workpermitTypes",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                                  },
                                  [
                                    _c("c-checkbox", {
                                      attrs: {
                                        isArray: false,
                                        editable:
                                          _vm.editable && _vm.selectedWork,
                                        codeGroupCd: "WO_RISK_FACTOR",
                                        itemText: "codeName",
                                        itemValue: "code",
                                        label: "위험요인",
                                        name: "workRiskFactors",
                                      },
                                      model: {
                                        value: _vm.workData.workRiskFactors,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.workData,
                                            "workRiskFactors",
                                            $$v
                                          )
                                        },
                                        expression: "workData.workRiskFactors",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      staticStyle: { "padding-top": "15px !important" },
                    },
                    [
                      _c(
                        "c-table",
                        {
                          ref: "grid",
                          attrs: {
                            title: "작업계획별 소요자재",
                            columns: _vm.grid.columns,
                            data: _vm.workData.materialList,
                            gridHeight: _vm.grid.height,
                            editable: _vm.editable && _vm.selectedWork,
                            hideBottom: true,
                            isExcelDown: false,
                            filtering: false,
                            isFullScreen: false,
                            columnSetting: false,
                            selection: "multiple",
                            rowKey: "materialCd",
                          },
                          on: {
                            "table-data-change": _vm.tableDataChangeMaterial,
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "table-button" },
                            [
                              _c(
                                "q-btn-group",
                                { attrs: { outline: "" } },
                                [
                                  _vm.editable && _vm.isOld && _vm.selectedWork
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "설비BOM 추가",
                                          icon: "add",
                                        },
                                        on: { btnClicked: _vm.addRowBom },
                                      })
                                    : _vm._e(),
                                  _vm.editable && _vm.isOld && _vm.selectedWork
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "자재마스터 추가",
                                          icon: "add",
                                        },
                                        on: { btnClicked: _vm.addRow },
                                      })
                                    : _vm._e(),
                                  _vm.editable && _vm.isOld && _vm.selectedWork
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "제외",
                                          icon: "remove",
                                        },
                                        on: { btnClicked: _vm.removeRow },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c(
                        "c-table",
                        {
                          ref: "grid2",
                          attrs: {
                            title: "작업계획별 서비스(외주)",
                            columns: _vm.grid2.columns,
                            data: _vm.workData.serviceList,
                            gridHeight: _vm.grid2.height,
                            editable: _vm.editable && _vm.selectedWork,
                            hideBottom: true,
                            isExcelDown: false,
                            filtering: false,
                            isFullScreen: false,
                            columnSetting: false,
                            selection: "multiple",
                            rowKey: "vendorCd",
                          },
                          on: {
                            "table-data-change": _vm.tableDataChangeService,
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "table-button" },
                            [
                              _c(
                                "q-btn-group",
                                { attrs: { outline: "" } },
                                [
                                  _vm.editable && _vm.isOld && _vm.selectedWork
                                    ? _c("c-btn", {
                                        attrs: { label: "추가", icon: "add" },
                                        on: { btnClicked: _vm.addRowService },
                                      })
                                    : _vm._e(),
                                  _vm.editable && _vm.isOld && _vm.selectedWork
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "제외",
                                          icon: "remove",
                                        },
                                        on: {
                                          btnClicked: _vm.removeRowService,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c(
                        "c-table",
                        {
                          ref: "toolGrid",
                          attrs: {
                            title: "공기구",
                            columns: _vm.toolGrid.columns,
                            data: _vm.workData.toolList,
                            gridHeight: _vm.toolGrid.height,
                            editable: _vm.editable && _vm.selectedWork,
                            hideBottom: true,
                            isExcelDown: false,
                            filtering: false,
                            isFullScreen: false,
                            columnSetting: false,
                            selection: "multiple",
                            rowKey: "toolId",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "table-button" },
                            [
                              _c(
                                "q-btn-group",
                                { attrs: { outline: "" } },
                                [
                                  _vm.editable && _vm.isOld && _vm.selectedWork
                                    ? _c("c-btn", {
                                        attrs: { label: "추가", icon: "add" },
                                        on: { btnClicked: _vm.addRowTool },
                                      })
                                    : _vm._e(),
                                  _vm.editable && _vm.isOld && _vm.selectedWork
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "제외",
                                          icon: "remove",
                                        },
                                        on: { btnClicked: _vm.removeRowTool },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c(
                        "c-table",
                        {
                          ref: "workerGrid",
                          attrs: {
                            title: "작업자",
                            columns: _vm.workerGrid.columns,
                            data: _vm.workData.workerList,
                            gridHeight: _vm.workerGrid.height,
                            editable: _vm.editable && _vm.selectedWork,
                            hideBottom: true,
                            isExcelDown: false,
                            filtering: false,
                            isFullScreen: false,
                            columnSetting: false,
                            selection: "multiple",
                            rowKey: "mdmSwsWorkerId",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "table-button" },
                            [
                              _c(
                                "q-btn-group",
                                { attrs: { outline: "" } },
                                [
                                  _vm.editable && _vm.isOld && _vm.selectedWork
                                    ? _c("c-btn", {
                                        attrs: { label: "선택", icon: "add" },
                                        on: { btnClicked: _vm.addRowWorker },
                                      })
                                    : _vm._e(),
                                  _vm.editable && _vm.isOld && _vm.selectedWork
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "직접추가",
                                          icon: "add",
                                        },
                                        on: { btnClicked: _vm.addRowWorker2 },
                                      })
                                    : _vm._e(),
                                  _vm.editable && _vm.isOld && _vm.selectedWork
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "제외",
                                          icon: "remove",
                                        },
                                        on: { btnClicked: _vm.removeRowWorker },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }