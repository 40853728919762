<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="개정" :noHeader="true" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="isOld">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="row">
                <div class="col-2">
                  <c-text
                    :required="editable && isRevision"
                    :editable="editable && isRevision"
                    label="개정번호"
                    name="revisionNum"
                    v-model="wsData.revisionNum">
                  </c-text>
                </div>
                <div class="col-2">
                  <c-text
                    :editable="false"
                    label="개정일시"
                    name="regDtStr"
                    v-model="wsData.regDtStr">
                  </c-text>
                </div>
                <div class="col-2">
                  <c-text
                    :editable="false"
                    label="개정자"
                    name="regUserName"
                    v-model="wsData.regUserName">
                  </c-text>
                </div>
                <div class="col-6">
                  <c-text
                    :required="editable && isRevision"
                    :editable="editable && isRevision"
                    label="개정사유"
                    name="revisionContent"
                    v-model="wsData.revisionContent">
                  </c-text>
                </div>
              </div>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card class="cardClassDetailForm" title="작업표준 상세">
            <template slot="card-select">
              <c-select
                :editable="!isRevision"
                v-if="popupParam.mdmSwsId"
                :comboItems="revdata"
                type="custom"
                typetext="개정이력"
                itemText="revisionNum"
                itemValue="mdmSwsId"
                name="selectedMdmSwsId"
                label=""
                v-model="selectedMdmSwsId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                
                <c-btn v-if="!isOld && editable" label="이전 정비작업 불러오기" icon="upload_file" @btnClicked="copyData" />
                <c-btn
                  v-show="editable && isOld && !isRevision"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="setRevision" />
                <c-btn
                  v-show="editable && isOld && isRevision"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="cancelRevision" />
                <c-btn
                  v-if="editable && isOld"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteSwsInfo" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="wsData"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveSwsInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="정비작업표준 명"
                  name="swsName"
                  v-model="wsData.swsName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable"
                  codeGroupCd="WO_WORK_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="woWorkTypeCd"
                  label="정비유형"
                  v-model="wsData.woWorkTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  :required="true"
                  :editable="editable"
                  name="plantCd"
                  v-model="wsData.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-equip
                  :required="true"
                  :editable="editable"
                  label="대표설비Tag"
                  name="equipmentCd"
                  v-model="wsData.equipmentCd"
                  @dataChange="equipChange">
                </c-equip>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-func-location 
                  type="edit" 
                  :plantCd="wsData.plantCd" 
                  :editable="editable" 
                  label="기능위치" 
                  name="funcLocationCd" 
                  v-model="wsData.funcLocationCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :editable="editable"
                  codeGroupCd="WORK_IMPORTANT_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="workImportantCd"
                  label="작업중요도"
                  v-model="wsData.workImportantCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-dept type="edit" :required="true" :editable="editable" label="정비오더 발행부서" name="workDeptCd" v-model="wsData.workDeptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :required="true"
                  :editable="editable"
                  :data="wsData"
                  type="user"
                  label="작업계획자"
                  name="planUserId"
                  v-model="wsData.planUserId" />
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-work-order
                  :editable="editable"
                  label="선행 정비오더" 
                  name="preWorkPlanId" 
                  v-model="wsData.preWorkPlanId"
                />
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-dept-multi
                  required
                  :isArray="false"
                  :editable="editable"
                  label="정비작업팀" 
                  name="workDepts" 
                  v-model="wsData.workDepts"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="wsData.useFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :rows="7"
                  label="정비요청 세부사항"
                  name="remark"
                  v-model="wsData.remark">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-table
                  ref="gridCost"
                  title="비용정산 규칙"
                  :columns="gridCost.columns"
                  :data="wsData.costList"
                  :gridHeight="gridCost.height"
                  :editable="editable"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="costCenterCd"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRowCost" v-if="editable" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRowCost" v-if="editable" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-table
                  ref="grid0"
                  title="관련설비"
                  :columns="grid0.columns"
                  :data="grid0.data"
                  :gridHeight="grid0.height"
                  :editable="editable"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="equipmentCd"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRowEquip" v-if="editable && isOld" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRowEquip" v-if="editable && isOld" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card :noHeader="true" :noMarginPadding="true">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-table
                ref="gridWork"
                title="작업계획 목록"
                :columns="gridWork.columns"
                :data="gridWork.data"
                :gridHeight="gridWork.height"
                :editable="editable"
                :isDense="true"
                bgHeaderClass="bg-grid-header-main"
                :hideBottom="true"
                :isExcelDown="false"
                :gridHeightAuto="true"
                :filtering="false"
                :isFullScreen="false"
                :columnSetting="false"
                @rowClick="rowClick"
              >
              </c-table>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <q-form ref="editForm2">
                <c-card class="cardClassDetailForm" title="작업계획 상세">
                  <template slot="card-button">
                    <q-btn-group outline >
                      <c-btn label="작업계획 추가" icon="add" @btnClicked="addRowWork" v-if="editable && isOld && !isRevision" />
                      <c-btn label="작업계획 삭제" icon="remove" @btnClicked="removeRowWork" v-if="editable && isOld&&selectedWork&&workUpdateMode" />
                      <c-btn
                        v-if="editable && isOld&&selectedWork"
                        :url="saveWorkUrl"
                        :isSubmit="isSave2"
                        :param="workData"
                        :mappingType="mappingType2"
                        label="작업계획 저장"
                        icon="save"
                        @beforeAction="saveWork"
                        @btnCallback="saveCallbackWork" />
                    </q-btn-group>
                  </template>
                  <template slot="card-detail">
                    <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" style="padding-top:25px !important;">
                      <c-text
                        :required="true"
                        :editable="editable&&selectedWork"
                        label="항목번호"
                        name="sortOrder"
                        type="number"
                        v-model="workData.sortOrder">
                      </c-text>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" style="padding-top:25px !important;">
                      <c-text
                        :required="true"
                        :editable="editable&&selectedWork"
                        label="작업명"
                        name="mdmSwsWorkName"
                        v-model="workData.mdmSwsWorkName">
                      </c-text>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" style="padding-top:25px !important;">
                      <c-select
                        :required="true"
                        :editable="editable&&selectedWork"
                        codeGroupCd="WO_OPR_TYPE_CD"
                        type="edit"
                        itemText="codeName"
                        itemValue="code"
                        name="workOprTypeCd"
                        label="작업제어구분"
                        v-model="workData.workOprTypeCd"
                        @datachange="changeWorkOprType"
                      ></c-select>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="workData.workOprTypeCd !== 'WOTC000001'" style="padding-top:25px !important;">
                      <c-vendor
                        :editable="editable&&selectedWork"
                        label="작업업체"
                        name="vendorCd"
                        v-model="workData.vendorCd" />
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="workData.workOprTypeCd === 'WOTC000001'" style="padding-top:25px !important;">
                      <c-dept type="edit" :isFirstValue="workUpdateMode" :editable="editable&&selectedWork" label="작업부서" name="workDeptCd" v-model="workData.workDeptCd" />
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <c-checkbox
                        :isArray="false"
                        :editable="editable&&selectedWork"
                        codeGroupCd="SOP_WORK_TYPE_CD"
                        itemText="codeName"
                        itemValue="code"
                        label="작업허가서 구분"
                        name="workpermitTypes"
                        v-model="workData.workpermitTypes"
                      />
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <c-checkbox
                        :isArray="false"
                        :editable="editable&&selectedWork"
                        codeGroupCd="WO_RISK_FACTOR"
                        itemText="codeName"
                        itemValue="code"
                        label="위험요인"
                        name="workRiskFactors"
                        v-model="workData.workRiskFactors"
                      />
                    </div>
                    <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="workData.workOprTypeCd !== 'WOTC000001'">
                      <c-text
                        :editable="editable&&selectedWork"
                        label="작업인원"
                        name="vendorWorkerCnt"
                        type="number"
                        suffix="명"
                        v-model="workData.vendorWorkerCnt">
                      </c-text>
                    </div> -->
                    <!-- <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" v-if="workData.workOprTypeCd === 'WOTC000001'">
                      <c-multi-field 
                        :editable="editable&&selectedWork"
                        :userInfo.sync="userInfo"
                        isArray
                        type="dept_user"
                        label="내부작업자" 
                        name="workUsers" 
                        v-model="workData.workUsers" />
                    </div> -->
                  </template>
                </c-card>
              </q-form>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:15px !important;">
              <c-table
                ref="grid"
                title="작업계획별 소요자재"
                :columns="grid.columns"
                :data="workData.materialList"
                :gridHeight="grid.height"
                :editable="editable&&selectedWork"
                :hideBottom="true"
                :isExcelDown="false"
                :filtering="false"
                :isFullScreen="false"
                :columnSetting="false"
                selection="multiple"
                rowKey="materialCd"
                @table-data-change="tableDataChangeMaterial"
              >
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn label="설비BOM 추가" icon="add" @btnClicked="addRowBom" v-if="editable && isOld&&selectedWork" />
                    <c-btn label="자재마스터 추가" icon="add" @btnClicked="addRow" v-if="editable && isOld&&selectedWork"/>
                    <c-btn label="제외" icon="remove" @btnClicked="removeRow" v-if="editable && isOld&&selectedWork"/>
                  </q-btn-group>
                </template>
              </c-table>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-table
                ref="grid2"
                title="작업계획별 서비스(외주)"
                :columns="grid2.columns"
                :data="workData.serviceList"
                :gridHeight="grid2.height"
                :editable="editable&&selectedWork"
                :hideBottom="true"
                :isExcelDown="false"
                :filtering="false"
                :isFullScreen="false"
                :columnSetting="false"
                selection="multiple"
                rowKey="vendorCd"
                @table-data-change="tableDataChangeService"
              >
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn label="추가" icon="add" @btnClicked="addRowService" v-if="editable && isOld&&selectedWork"/>
                    <c-btn label="제외" icon="remove" @btnClicked="removeRowService" v-if="editable && isOld&&selectedWork"/>
                  </q-btn-group>
                </template>
              </c-table>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-table
                ref="toolGrid"
                title="공기구"
                :columns="toolGrid.columns"
                :data="workData.toolList"
                :gridHeight="toolGrid.height"
                :editable="editable&&selectedWork"
                :hideBottom="true"
                :isExcelDown="false"
                :filtering="false"
                :isFullScreen="false"
                :columnSetting="false"
                selection="multiple"
                rowKey="toolId"
              >
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn label="추가" icon="add" @btnClicked="addRowTool" v-if="editable && isOld&&selectedWork"/>
                    <c-btn label="제외" icon="remove" @btnClicked="removeRowTool" v-if="editable && isOld&&selectedWork"/>
                  </q-btn-group>
                </template>
              </c-table>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-table
                ref="workerGrid"
                title="작업자"
                :columns="workerGrid.columns"
                :data="workData.workerList"
                :gridHeight="workerGrid.height"
                :editable="editable&&selectedWork"
                :hideBottom="true"
                :isExcelDown="false"
                :filtering="false"
                :isFullScreen="false"
                :columnSetting="false"
                selection="multiple"
                rowKey="mdmSwsWorkerId"
              >
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn label="선택" icon="add" @btnClicked="addRowWorker" v-if="editable && isOld&&selectedWork"/>
                    <c-btn label="직접추가" icon="add" @btnClicked="addRowWorker2" v-if="editable && isOld&&selectedWork"/>
                    <c-btn label="제외" icon="remove" @btnClicked="removeRowWorker" v-if="editable && isOld&&selectedWork"/>
                  </q-btn-group>
                </template>
              </c-table>
            </div>
          </template>
        </c-card>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="padding-top:25px !important;"> 
        <c-upload 
          :attachInfo="attachInfo"
          :editable="editable &&selectedWork"
          label="도면정보">
        </c-upload>
      </div> -->
    <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-standard-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmSwsId: '',
        plantCd: null,
        updateFlag: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      saveUrl: transactionConfig.mdm.sws.mst.insert.url,
      mappingType: 'POST',
      mappingType2: 'PUT',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      equiplistUrl: '',
      insertequipUrl: '',
      removeequipUrl: '',
      costlistUrl: '',
      insertcostUrl: '',
      removecostUrl: '',
      worklistUrl: '',
      workgetUrl: '',
      saveWorkUrl: transactionConfig.mdm.sws.work.insert.url,
      removeworkUrl: '',
      editable: true,
      isSave: false,
      isSave2: false,
      isRevision: false,
      workUpdateMode: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SWS_FILE',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      revdata: [],
      selectedMdmSwsId: '',
      wsData: {
        mdmSwsId: '',  // 안전작업 표준 일련번호
        plantCd: null,  // 사업장코드
        swsNo: '',  // 안전작업 표준 관리번호
        swsName: '',  // 정비작업표준 명
        purpose: '',  // 목적
        workImportantCd: null,  // 작업중요도
        termTm: '',  // 작업예상시간
        swpFlag: 'N',  // 안전작업허가서 발행필요 여부
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        regUserName: '',  // 등록자 ID
        regDtStr: '',  // 등록일
        chgUserId: '',  // 수정자 ID
        sysRevision: 1,  // 시스템 개정번호
        costList: [],
        equipList: [],
        groupId: '',  // 일련번호 키
        revisionNum: '',  // 개정번호
        revisionContent: '제정',  // 개정사유
        revisionFlag: 'N'
      },
      workData: {
        mdmSwsId: '',  // 정비오더 일련번호
        mdmSwsWorkId: '',  // 작업계획 일련번호
        mdmSwsWorkName: '',  // 작업내용
        sortOrder: '',  // 순번
        workOprTypeCd: null,  // 작업제어구분
        workStartDt: '',  // 작업 시작일
        workEndDt: '',  // 작업 종료일
        workpermitTypes: '',  // 작업허가서 종류
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        workDeptCd: '',  // 작업부서
        vendorCd: '',  // 작업업체
        vendorWorkerCnt: '',  // 작업업체 작업인원
        workHolidayFlag: 'N',
        materialList: [],
        deleteMaterialList: [],
        serviceList: [],
        deleteServiceList: [],
        toolList: [],
        deleteToolList: [],
        workerList: [],
        deleteWorkerList: [],
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      selectedWork: false,
      grid0: {
        columns: [
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비Tag',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'equipmentNum',
            field: 'equipmentNum',
            label: '설비관리번호',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'center',
            sortable: false,
          },
          // {
          //   name: 'funcLocationName',
          //   field: 'funcLocationName',
          //   label: '기능위치',
          //   align: 'center',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '170px',
      },
      gridWork: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '항목번호',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'mdmSwsWorkName',
            field: 'mdmSwsWorkName',
            label: '작업명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'workOprTypeName',
            field: 'workOprTypeName',
            label: '작업제어구분',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'workDeptName',
            field: 'workDeptName',
            label: '작업부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'workpermitTypeNames',
            field: 'workpermitTypeNames',
            label: '작업허가서구분',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'materialNames',
            field: 'materialNames',
            label: '소요자재',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'vendorNames',
            field: 'vendorNames',
            label: '서비스 (외주)',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
        ],
        data: [],
        height: '336px',
      },
      gridCost: {
        columns: [
          {
            name: 'costCenterNo',
            field: 'costCenterNo',
            label: '코스트센터 번호',
            align: 'center',
            style: 'width:110px',
            sortable: false,
          },
          {
            name: 'costCenterName',
            field: 'costCenterName',
            label: '코스트센터 명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'calculatePercent',
            field: 'calculatePercent',
            label: '정산율(%)',
            align: 'center',
            type: 'number',
            style: 'width:90px',
            sortable: false,
          },
        ],
        data: [],
        height: '170px',
      },
      grid: {
        columns: [
          {
            name: 'materialNo',
            field: 'materialNo',
            label: '자재번호',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재',
            align: 'center',
            style: 'width: 300px',
            sortable: false,
          },
          {
            name: 'materialDesc',
            field: 'materialDesc',
            label: '용도',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'unitPrice',
            field: 'unitPrice',
            label: '단가',
            type: 'number',
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'unitEa',
            field: 'unitEa',
            label: '수량',
            type: 'number',
            required: true,
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'priceCost',
            field: 'priceCost',
            label: '소계',
            type: 'cost',
            style: 'width: 150px',
            sortable: false,
          },
        ],
        data: [],
        height: '200px',
      },
      grid2: {
        columns: [],
        data: [],
        height: '200px',
      },
      toolGrid: {
        columns: [
          {
            name: 'toolName',
            field: 'toolName',
            label: '공기구명',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'toolStandard',
            field: 'toolStandard',
            label: '규격',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'toolCnt',
            field: 'toolCnt',
            label: '수량(개)',
            type: 'number',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: '220px',
      },
      workerGrid: {
        columns: [
          {
            name: 'workerName',
            field: 'workerName',
            label: '작업자',
            style: 'width:150px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'skillName',
            field: 'skillName',
            label: '기술수준',
            style: 'width:300px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
        height: '220px',
      },
      copyUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.mdmSwsId)
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.detailUrl = selectConfig.mdm.sws.mst.get.url;
      this.saveUrl = transactionConfig.mdm.sws.mst.insert.url;
      this.deleteUrl = transactionConfig.mdm.sws.mst.delete.url;
      this.copyUrl = transactionConfig.mdm.sws.mst.copy.url;
      this.revlistUrl = selectConfig.mdm.sws.mst.get.url + '/revs';

      
      this.equiplistUrl = selectConfig.mdm.sws.equip.list.url;
      this.insertequipUrl = transactionConfig.mdm.sws.equip.save.url;
      this.removeequipUrl = transactionConfig.mdm.sws.equip.delete.url;
      this.costlistUrl = selectConfig.mdm.sws.cost.list.url;
      this.insertcostUrl = transactionConfig.mdm.sws.cost.save.url;
      this.removecostUrl = transactionConfig.mdm.sws.cost.delete.url;
      
      this.worklistUrl = selectConfig.mdm.sws.work.list.url;
      this.workgetUrl = selectConfig.mdm.sws.work.get.url;
      this.saveworkUrl = transactionConfig.mdm.sws.work.insert.url;
      this.removeworkUrl = transactionConfig.mdm.sws.work.delete.url;


      this.getData();
      this.getColumn();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.mdmSwsId) {
        this.$set(this.attachInfo, 'taskKey', this.popupParam.mdmSwsId)
        this.getDetail(this.popupParam.mdmSwsId, true);
        this.selectedMdmSwsId = this.popupParam.mdmSwsId
      } else {
        this.wsData.planUserId = this.$store.getters.user.userId;

      }
    },
    getDetail(_mdmSwsId, _check) {
      this.$http.url = this.detailUrl;
      this.$http.param = {mdmSwsId: _mdmSwsId}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.wsData = _result.data;
        this.isRevision = false;
        this.wsData.revisionFlag = 'N';
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.mdmSwsId == this.popupParam.mdmSwsId) {
          this.editable = this.$route.meta.editable;
          this.$set(this.popupParam, 'updateFlag', this.selectedMdmSwsId + '|false')
        } else {
          this.editable = false;
          this.$set(this.popupParam, 'updateFlag', this.selectedMdmSwsId + '|true')
        }
        if (this.wsData.costCenterCd && this.wsData.costList.length == 0) {
          this.wsData.costList.push({
            mdmSwsId: this.popupParam.mdmSwsId,  // 작업계획 일련번호
            costCenterCd: this.wsData.costCenterCd,  // 코스트센터 코드
            costCenterNo: this.wsData.costCenterNo,  // 코스트센터 코드
            costCenterName: this.wsData.costCenterName,  // 코스트센터 코드
            calculatePercent: 100,  // 정산율
            calculateCost: '',  // 정산금액
            remark: '',  // 비고
            editFlag: 'C',
          })
        }
        if (!this.wsData.planUserId) {
          this.wsData.planUserId = this.$store.getters.user.userId;
        }
        this.getEquipList(_mdmSwsId);
        this.getWorks(_mdmSwsId);
      },);
    },
    getEquipList(_mdmSwsId) {
      if (this.popupParam.mdmSwsId || _mdmSwsId) {
        this.$http.url = this.equiplistUrl;
        this.$http.param = {mdmSwsId: _mdmSwsId || this.popupParam.mdmSwsId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid0.data = _result.data;
        },);
      }
    },
    getWorks(_mdmSwsId) {
      if (this.popupParam.mdmSwsId || _mdmSwsId) {
        this.$http.url = this.worklistUrl;
        this.$http.param = {mdmSwsId: _mdmSwsId || this.popupParam.mdmSwsId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.selectedWork = false;
          this.workUpdateMode = false;
          this.gridWork.data = _result.data;
        },);
      }
    },
    getColumn() {
      this.$comm.getComboItems('WO_COST_FACOTR_CD').then(_result => {
        this.grid2.columns = [
        {
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          {
            required: true,
            name: 'workDt',
            field: 'workDt',
            label: '작업일',
            align: 'center',
            type: 'date',
            style: 'width: 140px',
            sortable: false,
          },
          {
            name: 'costFactorCd',
            field: 'costFactorCd',
            label: '원가요소',
            align: 'center',
            sortable: false,
            style: 'width: 200px',
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result,
          },
          {
            name: 'manCnt',
            field: 'manCnt',
            label: '작업인원',
            type: 'number',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'priceCost',
            field: 'priceCost',
            label: '인력단가',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'manCost',
            field: 'manCost',
            label: '인건비',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'materialCost',
            field: 'materialCost',
            label: '자재비',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'sumCost',
            field: 'sumCost',
            label: '합계',
            type: 'cost',
            style: 'width: 120px',
            sortable: false,
          },
        ]
      });
    },
    setRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.wsData.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.wsData.revisionNum = _result.data;
        this.wsData.revisionContent = '';
        this.wsData.regDtStr = '';
        this.wsData.regUserName = '';
        this.isRevision = true;
        /**
         * 첨부파일 셋팅
         */
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.popupParam.mdmSwsId)
        this.$set(this.attachInfo, 'isRev', true)
      });
    },
    cancelRevision() {
      this.isRevision = false;
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'taskKey', this.popupParam.mdmSwsId)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.revdata = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        if (row != this.wsData.mdmSwsId) {
          this.selectedMdmSwsId = row;
          this.getDetail(this.selectedMdmSwsId, false);
          this.$set(this.attachInfo, 'taskKey', this.selectedMdmSwsId)
        }
      }
    },
    saveSwsInfo() {
      if (this.popupParam.mdmSwsId) {
        this.saveUrl = transactionConfig.mdm.sws.mst.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.sws.mst.insert.url;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';
      if (this.isRevision) {
        saveMsg = '현재버전을 개정('+this.wsData.revisionNum+')하여 저장하시겠습니까?';
      }
      let totalPercent = 0;
      this.$_.forEach(this.wsData.costList, _item => {
        totalPercent += parseInt(_item.calculatePercent)
      });
      if (totalPercent != 100) {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '정산율의 총 합이 100이어야 합니다.', /* 작업계획취소가 처리되었습니다. */
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$refs['editForm'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: saveMsg,
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.wsData.regUserId = this.$store.getters.user.userId;
                this.wsData.chgUserId = this.$store.getters.user.userId;
                // 개정시
                if (this.isRevision) {
                  this.saveUrl = transactionConfig.mdm.sws.mst.insert.url;
                  this.mappingType = 'POST';
                  this.wsData.sysRevision = parseInt(this.wsData.sysRevision) + 1;
                  this.wsData.revisionFlag = 'Y';
                }
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      }
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.mdmSwsId = result.data.mdmSwsId
      this.$set(this.attachInfo, 'taskKey', result.data.mdmSwsId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    deleteSwsInfo() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '작업표준과 관련된 데이터가 모두 삭제됩니다.\r\n삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.selectedMdmSwsId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    
    addRowEquip() {
      this.popupOptions.title = '설비 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.wsData.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowEquip;
    },
    closeaddRowEquip(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      let _saveData = [];
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid0.data, { equipmentCd: item.equipmentCd }) === -1) {
            _saveData.push({
              mdmSwsId: this.popupParam.mdmSwsId,  // 작업지시번호
              equipmentNo: item.equipmentNo,  // 작업자 사번
              equipmentNum: item.equipmentNum,
              equipmentCd: item.equipmentCd,
              equipmentName: item.equipmentName,
              funcLocationName: item.funcLocationName,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              mainEquipFlag: 'N',
              editFlag: 'C',
            })
          }
        });
        this.$http.url = this.insertequipUrl;
        this.$http.type = 'POST';
        this.$http.param = _saveData;
        this.$http.request(() => {
          this.getEquipList();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        });
      }
    },
    removeRowEquip() {
      let selectData = this.$refs['grid0'].selected;
      
      let filterData = this.$_.filter(selectData, item => {
        return item.mainEquipFlag === 'Y'
      })
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else if (filterData && filterData.length > 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '대표설비는 삭제할 수 없습니다.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.removeequipUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid0.data = this.$_.reject(this.grid0.data, item);
              })
              this.$refs['grid0'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    equipChange(data) {
      // 기능위치 변경
      if (data.funcLocationCd !== null) {
        if (this.wsData.funcLocationCd === null) {
          this.wsData.funcLocationCd = data.funcLocationCd
        } else {
          if (this.wsData.funcLocationCd != data.funcLocationCd) {
            this.wsData.funcLocationCd = data.funcLocationCd
          }
        }
      }
      if (data) {
        if (this.$_.findIndex(this.grid0.data, { equipmentCd: data.equipmentCd }) > -1) {
          this.$http.url = this.removeequipUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values([{
              mdmSwsId: this.popupParam.mdmSwsId,  // 작업지시번호
              equipmentNo: data.equipmentNo,
              equipmentCd: data.equipmentCd,
              equipmentName: data.equipmentName,
              funcLocationName: data.funcLocationName,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
            }])
          };
          this.$http.request(() => {
            this.$refs['grid0'].$refs['compo-table'].clearSelection();
            this.saveMainEquip(data)
          },);
        } else {
          this.saveMainEquip(data);
        }
        if(this.wsData.costList.length == 0) {
          this.wsData.costList.push({
            mdmSwsId: this.popupParam.mdmSwsId,  // 작업계획 일련번호
            costCenterCd: data.costCenterCd,  // 코스트센터 코드
            costCenterNo: data.costCenterNo,  // 코스트센터 코드
            costCenterName: data.costCenterName,  // 코스트센터 코드
            calculatePercent: 100,  // 정산율
            calculateCost: '',  // 정산금액
            remark: '',  // 비고
            editFlag: 'C',
          })
        }
      }
    },
    saveMainEquip(data) {
      if (this.popupParam.mdmSwsId) {
        this.$http.url = this.insertequipUrl;
        this.$http.type = 'POST';
        this.$http.param = [{
          mdmSwsId: this.popupParam.mdmSwsId,  // 작업지시번호
          equipmentNo: data.equipmentNo,
          equipmentCd: data.equipmentCd,
          equipmentName: data.equipmentName,
          funcLocationName: data.funcLocationName,
          regUserId: this.$store.getters.user.userId,  // 등록자 ID
          mainEquipFlag: 'Y',
          editFlag: 'C',
        }];
        this.$http.request(() => {
          this.getEquipList();
        });
      } else {
        this.grid0.data.push(
          {
            mdmSwsId: this.popupParam.mdmSwsId,  // 작업지시번호
            equipmentNo: data.equipmentNo,
            equipmentCd: data.equipmentCd,
            equipmentName: data.equipmentName,
            funcLocationName: data.funcLocationName,
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            mainEquipFlag: 'Y',
            editFlag: 'C',
          }
        );
        this.wsData.equipList.push(
          {
            mdmSwsId: this.popupParam.mdmSwsId,  // 작업지시번호
            equipmentNo: data.equipmentNo,
            equipmentCd: data.equipmentCd,
            equipmentName: data.equipmentName,
            funcLocationName: data.funcLocationName,
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            mainEquipFlag: 'Y',
            editFlag: 'C',
          }
        )
      }
    },
    rowClick(row) {
      this.selectedWork = true;
      this.workUpdateMode = true;
       if (row.mdmSwsWorkId) {
        this.$set(this.attachInfo, 'taskKey', row.mdmSwsWorkId)
      }
      // 상세조회
      this.$http.url = this.workgetUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmSwsId : this.popupParam.mdmSwsId,
        mdmSwsWorkId : row.mdmSwsWorkId,
      }
      this.$http.request((_result) => {
        this.workData = _result.data;
      },
      () => {
      });
    },
    addRowWork() {
      this.selectedWork = true;
      this.workUpdateMode = false;
      this.workData = {
        mdmSwsId: this.popupParam.mdmSwsId,  // 정비오더 일련번호
        mdmSwsWorkId: uid(),  // 작업계획 일련번호
        mdmSwsWorkName: '',  // 작업내용
        sortOrder: (this.gridWork.data.length + 1) * 10,  // 순번
        workOprTypeCd: null,  // 작업제어구분
        workpermitTypes: '',  // 작업허가서 종류
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        vendorCd: '',  // 작업업체
        vendorWorkerCnt: '',  // 작업업체 작업인원
        workHolidayFlag: 'N',
        materialList: [],
        deleteMaterialList: [],
        serviceList: [],
        deleteServiceList: [],
        toolList: [],
        deleteToolList: [],
        workerList: [],
        deleteWorkerList: [],
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID
      }
      this.$set(this.attachInfo, 'taskKey', '')
    },
    removeRowWork() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '작업계획을 삭제하시겠습니까?',
        // TODO : 작업계획의 모든 정보가 삭제됩니다.\r\n작업계획을 삭제하시겠습니까?
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.removeworkUrl, this.workData.mdmSwsWorkId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '작업계획이 삭제되었습니다.', /* 작업계획취소가 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.getWorks();
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveWork() {
      if (this.selectedWork && this.workUpdateMode) {
        this.saveWorkUrl = transactionConfig.mdm.sws.work.update.url;
        this.mappingType2 = 'PUT';
      } else {
        this.saveWorkUrl = transactionConfig.mdm.sws.work.insert.url;
        this.mappingType2 = 'POST';
      }
      this.$refs['editForm2'].validate().then(_result => {
        if (this.$comm.validTable(this.grid.columns, this.workData.materialList)) {
          if (this.$comm.validTable(this.grid2.columns, this.workData.serviceList)) {
            if (_result) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.workData.regUserId = this.$store.getters.user.userId;
                  this.workData.chgUserId = this.$store.getters.user.userId;
                  this.isSave2 = !this.isSave2;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('APP_VALID_ERROR');
            }
          }
        }
      });
    },
    saveCallbackWork(_result) {
      this.workData.mdmSwsWorkId = _result.data;
      this.$http.url = this.worklistUrl;
      this.$http.param = {mdmSwsId: this.popupParam.mdmSwsId};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.gridWork.data = _result.data;
        this.rowClick(this.workData);
      },);
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    addRowCost() {
      this.popupOptions.title = '코스트센터 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.wsData.plantCd,
        maintenanceFlag: '',
        purchaseFlag: '',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/cc/costCenterPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowCost;
    },
    closeaddRowCost(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.wsData.costList, { costCenterCd: item.costCenterCd }) === -1) {
            this.wsData.costList.push({
              mdmSwsId: this.popupParam.mdmSwsId,  // 작업계획 일련번호
              costCenterCd: item.costCenterCd,  // 코스트센터 코드
              costCenterNo: item.costCenterNo,  // 코스트센터 코드
              costCenterName: item.costCenterName,  // 코스트센터 코드
              calculatePercent: (this.wsData.costList.length == 0 && data.length == 1 ? '100' : ''),  // 정산율
              calculateCost: '',  // 정산금액
              remark: '',  // 비고
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRowCost() {
      let selectData = this.$refs['gridCost'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.removecostUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.wsData.costList = this.$_.reject(this.wsData.costList, item);
              })
              this.$refs['gridCost'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    changeWorkOprType() {
      if (this.workData.workOprTypeCd === 'WOTC000001') { // 자체작업
        this.workData.vendorCd = '';
        this.workData.vendorWorkerCnt = null;
      } else {
        this.workData.workDeptCd = '';
        this.workData.workUsers = [];
      }
    },
    copyData() {
      this.popupOptions.title = '기존 정비오더 검색'; // 정비작업표준 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.wsData.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/wod/workOrderPlanPopUp.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyPopup;
    },
    closeCopyPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.length === 1) {
        this.$http.url = selectConfig.wod.workorder.plan.copy.url;
        this.$http.param = {workPlanId: data[0].workPlanId};
        this.$http.type = 'GET';
         this.$http.request((_result) => {
          let _save = {};

          this.$set(_save, 'woWorkTypeCd', _result.data.woWorkTypeCd)
          this.$set(_save, 'swsName', _result.data.workPlanName)
          this.$set(_save, 'plantCd', _result.data.plantCd)
          this.$set(_save, 'equipmentCd', _result.data.equipmentCd)
          this.$set(_save, 'funcLocationCd', _result.data.funcLocationCd)
          this.$set(_save, 'workDeptCd', _result.data.workDeptCd)
          this.$set(_save, 'planUserId', _result.data.planUserId)
          this.$set(_save, 'workDepts', _result.data.workDepts)
          this.$set(_save, 'useFlag', 'Y')
          this.$set(_save, 'regUserId', this.$store.getters.user.userId)
          this.$set(_save, 'chgUserId', this.$store.getters.user.userId)
          this.$set(_save, 'remark', _result.data.remark)
          this.$set(_save, 'costList', _result.data.costList)
          this.$set(_save, 'workList', _result.data.workList)
          this.$set(_save, 'equipList', _result.data.equipList)

          this.$_.forEach(_save.workList, _item => {
            _item.mdmSwsWorkName = _item.workPlanWorkName
          })
          this.$http.url = this.copyUrl;
          this.$http.type = 'POST';
          this.$http.param = _save
          this.$http.request((_result) => {
            this.popupParam.mdmSwsId = _result.data.mdmSwsId
            this.getData();
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },);
      }
    },
    addRow() {
      this.popupOptions.title = '자재마스터 추가'; // 작업자재 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/materialPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRow;
    },
    closeaddRow(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workData.materialList, { materialCd: item.materialCd }) === -1) {
            this.workData.materialList.push({
              mdmSwsId: this.popupParam.mdmSwsId,  // 작업지시번호
              mdmSwsWorkId: this.workData.mdmSwsWorkId,
              materialCd: item.materialCd,  // 작업자재 일련번호
              materialNo: item.materialNo,  // 작업자재 일련번호
              materialName: item.materialName,  // 작업자재
              materialDesc: '',  // 작업자재 용도
              unitPrice: item.price,  // 단가
              unitEa: '',  // 수량
              priceCost: '',  // 소계
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workData.deleteMaterialList) this.workData.deleteMaterialList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workData.deleteMaterialList, { materialCd: item.materialCd }) === -1) {
            this.workData.deleteMaterialList.push(item)
          }
          this.workData.materialList = this.$_.reject(this.workData.materialList, item);
        })
        this.$refs['grid'].$refs['compo-table'].clearSelection();
      }
    },
    addRowBom() {
      this.popupOptions.title = '설비별 BOM 추가'; // 작업자재 추가
      this.popupOptions.param = {
        type: 'multiple',
        equipmentCds: this.grid0.data,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/bomPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowBom;
    },
    closeaddRowBom(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workData.materialList, { materialCd: item.equipmentBomConnCd }) === -1) {
            this.workData.materialList.push({
              mdmSwsId: this.popupParam.mdmSwsId,  // 작업지시번호
              mdmSwsWorkId: this.workData.mdmSwsWorkId,
              materialCd: item.equipmentBomConnCd,  // 작업자재 일련번호
              materialNo: item.materialNo,  // 작업자재 일련번호
              materialName: item.equipmentBomName,  // 작업자재
              materialDesc: '',  // 작업자재 용도
              unitPrice: item.price,  // 단가
              unitEa: '',  // 수량
              priceCost: '',  // 소계
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    addRowService() {
      this.popupOptions.title = '작업업체 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowService;
    },
    closeaddRowService(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workData.serviceList, { vendorCd: item.vendorCd }) === -1) {
            this.workData.serviceList.push({
              mdmSwsId: this.popupParam.mdmSwsId,  // 작업지시번호
              mdmSwsWorkId: this.popupParam.mdmSwsWorkId,
              vendorCd: item.vendorCd,
              vendorName: item.vendorName,
              workDate: '',
              unitEa: '',
              priceCost: '',
              costFactorCd: null,
              manCost: '',
              materialCost: '',
              sumCost: '',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRowService() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workData.deleteServiceList) this.workData.deleteServiceList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workData.deleteServiceList, { vendorCd: item.vendorCd }) === -1) {
            this.workData.deleteServiceList.push(item)
          }
          this.workData.serviceList = this.$_.reject(this.workData.serviceList, item);
        })
        this.$refs['grid2'].$refs['compo-table'].clearSelection();
      }
    },
    addRowTool() {
      this.popupOptions.title = '공기구 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.wsData.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/tl/toolPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRowTool;
    },
    closeRowTool(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workData.toolList, { toolId: item.toolId }) === -1) {
            this.workData.toolList.push({
              mdmSwsId: this.popupParam.mdmSwsId,
              mdmSwsWorkId: this.workData.mdmSwsWorkId,
              toolId: item.toolId,
              toolName: item.toolName,
              toolStandard: item.toolStandard,
              toolCnt: 0,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRowTool() {
      let selectData = this.$refs['toolGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workData.deleteToolList) this.workData.deleteToolList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workData.deleteToolList, { materialCd: item.materialCd }) === -1) {
            this.workData.deleteToolList.push(item)
          }
          this.workData.toolList = this.$_.reject(this.workData.toolList, item);
        })
        this.$refs['toolGrid'].$refs['compo-table'].clearSelection();
      }
    },
    addRowWorker() {
      this.popupOptions.title = '작업자 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.wsData.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRowWorker;
    },
    closeRowWorker(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.workData.workerList.push({
            mdmSwsId: this.popupParam.mdmSwsId,
            mdmSwsWorkId: this.workData.mdmSwsWorkId,
            mdmSwsWorkerId: uid(),
            skillName: item.skillName,
            workerName: item.userName,
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            editFlag: 'C',
          })
        });
      }
    },
    addRowWorker2() {
      this.workData.workerList.push({
        mdmSwsId: this.popupParam.mdmSwsId,
        mdmSwsWorkId: this.workData.mdmSwsWorkId,
        mdmSwsWorkerId: uid(),
        skillName: '',
        workerName: '',
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      })
    },
    removeRowWorker() {
      let selectData = this.$refs['workerGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workData.deleteWorkerList) this.workData.deleteWorkerList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workData.deleteWorkerList, { mdmSwsWorkerId: item.mdmSwsWorkerId }) === -1) {
            this.workData.deleteWorkerList.push(item)
          }
          this.workData.workerList = this.$_.reject(this.workData.workerList, item);
        })
        this.$refs['workerGrid'].$refs['compo-table'].clearSelection();
      }
    },
    tableDataChangeMaterial(props) {
      if (props.row['unitPrice'] > 0 && props.row['unitEa'] > 0) {
        this.$set(props.row, 'priceCost', parseInt(props.row['unitPrice']) * parseInt(props.row['unitEa']));
      } else {
        this.$set(props.row, 'priceCost', 0);
      }
    },
    tableDataChangeService(props, col) {
      if (col.name !== 'manCost' && col.name !== 'materialCost') {
        if (props.row['priceCost'] > 0 && props.row['manCnt'] > 0) {
          this.$set(props.row, 'manCost', parseInt(props.row['priceCost']) * parseInt(props.row['manCnt']));
          this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost'] || 0));
        } else {
          this.$set(props.row, 'manCost', 0);
          this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost'] || 0));
        }
      } else {
        this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost']));
      }
    },
  }
};
</script>